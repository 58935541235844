@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";
@import "app/styles/istock/Zindex.module.scss";

.galleryFixedAsset {
  width: 50%;
  vertical-align: top;
  margin: 0 0 40px;
  display: inline-block;
  padding: 10px;
  border: 1px solid $white;
  background-color: $grid-grey;
  margin: 0 0 -1px -1px;
  padding: 0;
  position: relative;

  @media #{$medium-up} {
    width: 25%;
  }

  @media #{$large-up} {
    width: 20%;
  }

  @media #{$xlarge-up} {
    width: 16.665%;
  }

  &:hover {
    background-color: rgba($white, 0.45);
    transition: background-color $complex-duration cubic-bezier(0.65, 0.05, 0.36, 1);
  }

  .container {
    height: 225px;
    padding: 5%;
    overflow: hidden;

    .link {
      position: relative;
      height: 100%;
      overflow: hidden;

      .figure {
        position: relative;
        max-height: 100%;
        height: 100%;

        .thumb {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          margin: auto;
          display: block;
          max-height: 100%;
          max-width: 100%;
          width: 100%;
          object-fit: contain;

          transition: opacity $complex-duration ease;
        }

        .figcaption {
          display: none;
        }
      }
    }

    .clipBadge {
      padding: 0;
    }

    .editorialOnly {
      position: absolute;
      top: 0;
      left: 0;
      margin: 4px;
      width: 31px;
      height: 25px;
      opacity: 0.8;
    }
  }
}
