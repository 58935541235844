@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.clipBadge {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  background-color: $is-background-grey;
  border-radius: 3px;
  line-height: 18px;
  padding: 0 2px;
  height: 28px;
}

.duration {
  margin: 0 6px;
}

.filmDefinition {
  margin: 0 6px;
  height: 20px;
  width: 28px;
}
