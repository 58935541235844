@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Zindex.module.scss";

$desktop-header-height: 93px;
$desktop-sticky-header-height: 120px;
$sticky-header-height: 55px;

.headerSearchBar {
  background-color: #e8eded;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: $sticky-header-height;

  @media #{$istock-medium-up} {
    height: auto;
  }
}

.headerSearchBarContainer {
  display: none;
  @media #{$istock-medium-up} {
    display: block;
    padding: 0.5rem 1.5rem;
    margin: 0;
  }
};

.placeholderHeaderSearchBar {
  height: $sticky-header-height;
  @media #{$istock-large-up} {
    padding: 12px 2em;
  }
}

.searchBar {
  z-index: 3;
  width: 100%;
}

@mixin baseStyle {
  display: block;
  width: 100%;
  background: white;
  position: fixed;
}

.container {
  width: 100%;
  @media #{$istock-medium-up} {
    @include baseStyle;
    animation: slideIn 0.300s ease;
    top: 0;
    z-index: $filter-panel-z-index;
  }
}

.docked {
  background-color: $is-med-dark-grey;
  width: 100%;
  display: flex;
  flex-direction: column;
  @media #{$istock-medium-up} {
    @include baseStyle;
    position: relative;
  }
}

.simpleDocked {
  background-color: $is-med-dark-grey;
  width: 100%;
  flex-direction: column;
  display: contents;
  @media #{$istock-medium-up} {
    @include baseStyle;
    display: contents;
    position: relative;
  }
}

.simpleContainer {
  display: contents;
  @media #{$istock-medium-up} {
    @include baseStyle;
    display: flex;
    animation: slideIn 0.300s ease;
    top: 0;
    z-index: $filter-panel-z-index;
  }
}

.filterBar {
  display: none;
  @media #{$istock-medium-up} {
    display: flex;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  }
}

.filterBarFilters {
  display: block;
  padding: 0 1.5rem;
  width: calc(100% - 66px);

  @media #{$istock-large-up} {
    width: calc(100% - 216px);
  }

  & > div {
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
  }
}

.filterBarFiltersRefinePanelOpen {
  @extend .filterBarFilters;

  @media #{$istock-large-up} {
    width: calc(100% - 265px);
  }
}

@keyframes slideIn {
  from { top: -203px; }

  to { top: 0; }
}
