@import 'app/styles/istock/Legacy.module.scss';
@import 'app/styles/istock/Color.module.scss';

.container {
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 1rem;
  background-color: $white;
  border: 1px solid $is-grey;
  border-radius: 4px;
  width: fit-content;
  font-size: 14px;
  min-height: 48px;
  padding: 0 20px;

  svg {
    flex-shrink: 0;
  }
}

.languageContainer {
  @extend .container;
  padding-right: 20px;
}

.content {
  display: inline-block;
  background: none;
  border: none;

  span {
    span {
      font-weight: 700;
    }
  }

  .link {
    font-weight: 700;
    cursor: pointer;
  }
}
