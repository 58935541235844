@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.button {
  right: 5px;
  -webkit-border-radius: 10%;
  -moz-border-radius: 10%;
  -ms-border-radius: 10%;
  -o-border-radius: 10%;
  border-radius: 10%;
  transition: all 0.300s ease-in-out;
  background-color: white;
  border: 1px solid $light-grey;
  height: 32px;
  width: 32px;
  cursor: pointer;
  z-index: 10;

  @media #{$mobile} {
    display: none;
  }

  &:hover {
    -webkit-box-shadow: inset 0 0 0 3px $grid-grey;
    -moz-box-shadow: inset 0 0 0 3px $grid-grey;
    box-shadow: inset 0 0 0 3px $grid-grey;
    border-color: $light-grey;
    color: $dark-grey;

    .icon {
      fill: $dark-grey;
    }
  }

  &:focus {
    outline: 0;
  }
}

.showButton {
  opacity: 1;
}

.hideButton {
  opacity: 0;
}

.absolute {
  position: absolute;
  bottom: auto;
}

.fixed {
  position: fixed;
  bottom: 15px;
}

.icon {
  display: block;
  position: relative;
  top: 0;
  right: -10px;
  transform: rotate(270deg);
  fill: $shale-grey;
}