@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Zindex.module.scss";
@import "app/styles/istock/Color.module.scss";

.galleryContainer {
  width: 100%;
  position: relative;
  min-height: 40rem;
}

.rowContainerBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  position: fixed;
  top: -1px;
  z-index: 2;

  animation: slideIn 0.300s ease;

  @media #{$istock-medium-up} {
    position: unset;
    top: unset;
    z-index: unset;
  }
}

.docked {
  background-color: $is-med-dark-grey;
  width: 100%;
  display: flex;
  @media #{$istock-medium-up} {
    position: relative;
  }
}

.rowContainerContent {
  display: flex;
  flex-direction: row;
  width: auto;
}

.columnContainer {
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 100%;
}

// Simplified Gallery UI
// Used for Gateway, SBI, Recently Viewed etc.
.simpleRowContainer {
  display: flex;
  flex-direction: row;
  width: 100%;
  top: 0;
  z-index: 2;
  position: fixed;

  animation: slideIn 0.300s ease;

  @media #{$istock-large-up} {
    z-index: 5;
  }
}

.legalText{
  text-align: center;
  font-size: 0.75rem;
  line-height: 14.4px;
  font-weight: 400;
  color: $dark-grey;
  padding: 0 1.7%;
}

@keyframes slideIn {
  from { top: -203px; }

  to { top: 0; }
}