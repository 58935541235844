@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.content {
  justify-self: center;
  font-size: .75em;

  // If breacrumbs feature is made it permanent
  // we want to move this to .content general class
  &Breadcrumbs {
    margin-top: 24px;
  }

  &Top {
    margin-bottom: 0;
    margin-top: 1rem;
  }

  &Sticky {
    margin-bottom: 10px;
  }
}

.content a {
  color: $is-green;
}

.content a:hover {
  color: $is-dark-green;
  text-decoration: none;
}
