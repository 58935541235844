@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Zindex.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_functions.scss";

%search-button {
  cursor: pointer;
  font-size: rem(14);
  line-height: 24px;
  font-weight: 400;
  border-radius: 2px;
  padding: 8px;
  -webkit-tap-highlight-color: transparent;
  z-index: 1;
}

.actions {
  opacity: 1;

  .topLeftWrapper {
    display: flex;
    position: absolute;
    top: 10px;
    left: 10px;
  }

  .topRightWrapper {
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    gap: 10px;
  }

  .editorialOnly {
    color: $white;
    width: 31px;
    height: 25px;
    margin-right: 5px;
    opacity: 0.8;
    z-index: 1;
  }

  .freeWithTrialButton {
    @extend %search-button;
    color: white;
    background-color: $href-color;

    &:hover {
      background-color: $href-hover-color;
    }
  }

  .similarImages {
    @extend %search-button;
    color: white;
    background-color: rgba(0, 0, 0, 0.5);

    &:hover {
      background-color: $href-color;
    }
  }

  .bottomContainer {
    position: absolute;
    flex: 1 1 auto;
    bottom: 10px;
    left: 10px;
    right: 10px;
    display: flex;
    flex-wrap: wrap-reverse;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    pointer-events: none;

    > * {
      pointer-events: auto;
    }

    .discountBadge{
      @extend %search-button;
      color: $white;
      background-color: $href-color;
    }

    .signature {
      color: $white;
      z-index: 1;
      pointer-events: none;
    }

}
  .signatureTopLeft {
    color: white;
    z-index: 1;
    pointer-events: none;
  }

  .boards {
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 8px;

    &:hover {
      background-color: $href-color;
    }
  }
}
