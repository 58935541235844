@use './ModificationToolsMenu.shared.module.scss';
@use '@unisporkal/alliance-ui-theme/dist/styles/iStock' as iStockTheme;

.rootMosaic {
  background-color: iStockTheme.$true-white;
  border-color: iStockTheme.$is-black;
}

.rootFixed {
  background-color: iStockTheme.$true-white;
  border-color: iStockTheme.$is-black;
}

.generateButtonMosaic {
  color: iStockTheme.$true-white;
  background-color: rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: iStockTheme.$secondary;
  }
}

.generateButtonAsset {
  color: iStockTheme.$medium-grey
}

.dropdown {
  .button {
    border-radius: 0;

    &:hover,
    &:focus {
      background-color: iStockTheme.$xxx-light-grey !important;
    }

    &:disabled {
      color: iStockTheme.$light-blue-grey;
    }
  }
}
