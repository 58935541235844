@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.bannerContainer {
  align-items: center;
  background: linear-gradient(140deg, rgb(3 94 139 / 93%), rgb(0 203 169 / 73%), rgb(0 225 80 / 4%)), linear-gradient(72deg, transparent, rgb(0 225 117 / 64%));
  background-size: cover;
  background-repeat: no-repeat;
  color: $white;
  height: 350px;
  width: 100%;
  padding: 48px;
  display: flex;
  flex-direction: row;
  justify-content: center;

  // Mobile
  @media #{'screen and (max-width: 540px)'} {
    display: none;
  }

  // Tablet
  @media #{'screen and (max-width: 800px)'} {
    flex-direction: column;
    padding: 32px;
  }
}

.leftText{
  display: flex;
  flex-direction: column;
  font-size: 2.3rem;
  line-height: normal;
  padding-right: 9%;

  // Tablet
  @media #{'screen and (max-width: 800px)'} {
    padding-right: 0;
    padding-bottom: 48px;
  }
}

.subHeaderText {
  padding-top: 16px;
  font-size: 0.85rem;
  font-weight: normal;
}

.bannerContainer .bannerCta {
  background-color: rgba($is-black, 0.5) !important; // overriding alliance ui styles
  color: $white;

  &:hover {
    background-color: $white !important; // overriding alliance ui styles
    color: rgba($is-black, 0.5);

    .bannerIcon {
      path {
        fill: $gi-black;
      }
    }
  }
}

.rightButton {
  min-width: max-content;
}

.bannerCta {
  color: $white;
  display: flex;
  text-decoration: none;
  cursor: pointer;
}

.bannerIcon {
  height: 28px;
  padding-right: 8px;
  path {
    fill: $white;
  }
}
