.body {
  overflow-y: hidden;
  width: 600px;
  padding: 0 2rem 1.5rem;
  margin-bottom: 1rem;
  height: 700px;
  max-height: 700px;
}

.header {
  flex-direction: column;
  align-items: flex-start;
}
