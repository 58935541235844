@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Zindex.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";

.container {
  display: flex;
}

.button {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 20px;
  margin-right: 8px;
  font-size: 14px;
  height: 40px;
  background: $white;
  cursor: pointer;
  border: 1px solid $is-grey;
  border-radius: 20px;
  outline: none;
  
  label {
    color: $is-black;
    cursor: pointer;
    white-space: nowrap;
  }

  .count {
    margin: 0 0 0 6px;
    padding-right: 1px;
    width: 18px;
    line-height: 18px;
    border-radius: 50%;
    text-align: center;
    font-size: 11px;
    font-weight: 800;
    color: $white;
    background-color: $is-green;
  }

  &:hover {
    border: 1px solid $is-black;
  }

  &Active {
    border: 1px solid $is-black;
  }

  &Highlight {
    border: 1px solid $is-black !important;
  }
}

.arrow {
  &Up {
    display: flex;
    padding-right: 10px;
    padding-bottom: 2px;
    transform: rotate(180deg);

    svg {
      width: 12px
    }
  }
  &Down {
    display: flex;
    padding-left: 10px;
    padding-top: 2px;

    svg {
      width: 12px
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: $filter-dropdown-z-index;
}

.dropdown {
  position: absolute;
  padding: 20px 18px;
  background: #FFFFFF;
  top: 46px;
  border-radius: 10px;
  border: 1px solid $is-grey;
  box-sizing: border-box;
  z-index: $filter-dropdown-z-index;

  &List {
    column-count: 2;
  }

  &Item {
    margin: 7px 0px;
    white-space: nowrap;
    font-size: 14px;
    min-width: 200px;
    cursor: pointer;
    display: flex;
    align-items: center;
    break-inside: avoid-column;

    &:first-child {
      margin-top: 0px;
    }

    input {
      display: none;
    }

    label {
      cursor: pointer;
      margin-top: 2px;
    }

    &:hover {
      .iconCheckmark {
        display: block;
        fill: $is-grey;
        width: 10px;
      }
    }
  }

  &Buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }
}

.dropdownFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  font-size: 12px;
}

.totalResults {
  color: #666E6E;
}

.clear {
  margin-right: 24px;
  font-size: 12px;
  color: #ADB9BA;
  cursor: pointer;

  &Active {
    color: $is-green;
  }
}

.apply {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
  margin-right: 8px;
  font-size: 14px;
  height: 41px;
  background: $is-green;

  border-radius: 5px;
  color: $white;
  cursor: pointer;

  &Disabled {
    background: $is-green;
    opacity: 40%;
    cursor: default;
  }

}

.checkbox {
  border: 1px solid #ADB9BA;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  margin-right: 11px;

  &Active {
    border: 1px solid $is-black;
  }
}

.iconCheckmark {
  display: none;

  &Active {
    display: block;
    fill: $is-black;
    width: 10px;
  }
}

.fixed {
  position: fixed;
  z-index: $sticky-filter-dropdown-z-index;
}

.panel {
  display: none;

  @media #{$istock-medium-up} {
    display: block;
  }
}

.panelHidden {
  visibility: hidden;
}
