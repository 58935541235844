@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "app/styles/istock/Color.module.scss";

.container {
  display: block;
  position: relative;
  width: 100%;
  height: 40px;
  margin-left: 0;
  border-left: none;
  padding-left: 0;
  overflow: hidden;
}

.navigation {
  display: none;

  @media #{$medium-up} {
    display: block;
  }

  button {
    z-index: 1;
  }

  &Left, &Right {
    position: absolute;
    top: 0;
    width: 30px;
    height: 40px;
    background-color: $grid-grey;
    outline: none;
    border: none;
    transition: all .3s ease;
    cursor: pointer;

    &:hover {
      background: $border-grey;
    }
    &:focus,
    &:active {
      border: none;
    }
  }

  &Right {
    right: 0;
    transform: rotate(180deg);
  }
}
