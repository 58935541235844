@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";

.container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .textHolder {
    display: flex;
    align-items: center;
  
    background-color: white;
    outline: none;
    box-shadow: none;
  
    margin-top: 15px;
  
    width: 190px;
    height: 2rem;

    .colorText {
      color: #0c0d0d;
      font-family: iStock Maquette,Helvetica,Arial,sans-serif;
      font-size: 0.875rem;
      width: 140px;
      margin-left: 10px;
    }

    .icon {
      cursor: pointer;
      margin-left: auto;
      margin-right: 10px;
      path {
        fill: #474747
      }
    }
  }

  .switchHolder {
    width: 190px;
    margin-top: 10px;
  }
}