@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";

@keyframes slide-out {
  0% {
    left: -100%;
  }

  100% {
    left: 0;
  }
}

@mixin baseContainer {
  display: flex;
  flex-direction: column;
  background-color: $grey;

  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  @media #{$istock-small-up} {
    width: 265px;
  }

  @media #{$istock-large-up} {
    animation: $complex-duration linear forwards slide-out;
    animation-timing-function: ease;
    background-color: $grey;
    z-index: 1;
  }

  @media screen and (prefers-reduced-motion: reduce) {
    animation: none;
  }
}

.containerOpen {
  @include baseContainer;
  position: fixed;
  left: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  // Could not use scrollbar-gutter to prevent content jitter caused by
  // `scrollbar-width: none`. So instead, toggle the transparency via hover.
  scrollbar-color: transparent transparent;

  &::-webkit-scrollbar {
    width: 8px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    opacity: 0;
  }

  &:hover {
    scrollbar-color: auto;

    &::-webkit-scrollbar-thumb {
      opacity: 1;
      background: $is-med-dark-grey;
      border-radius: 5px;
    }
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $is-med-dark-grey;
  }

  @media #{$istock-large-up} {
    position: relative;
  } 
}

.filterPanelContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
  transition: top .35s ease;
  position: fixed;
  width: 100vw;
  z-index: 4;

  @media #{$istock-small-up} {
    width: 265px;
  }

  @media #{$istock-large-up} {
    height: 97vh;
    z-index: 2;
    position: sticky;
  }

  &.noTerms {
    @media #{$istock-large-up} {
      top: 120px;
    }
  }
}

.filterPanelTransition {
  top: 175px;
  transition: top .35s ease;
}

.simpleFilterPanelTransition {
  top: 60px;
  transition: top .35s ease;
}

.filterPanelBackdrop {
  background-color: $grey;
  position: relative;
  left: 0;
  animation: .3s linear forwards slide-out;
  animation-timing-function: ease;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;

  display: none;
  height: 100vh;
  width: 100%;

  background-color: rgba(black, 0.5);

  @media #{$istock-small-up} {
    display: block;
  }

  @media #{$istock-large-up} {
    display: none;
  }
}

.noAnimation {
  animation: none;
}
