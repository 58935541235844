@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";

.option {
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;

  @mixin baseCheckbox {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 19px;
    width: 19px;

    .icon {
      margin: auto;
      display: none;
    }
  }

  .checkbox {
    @include baseCheckbox;
    border: 1px solid #666e6e;
  }

  .checkboxSelected {
    @include baseCheckbox;
    border: 1px solid #02a388;

    .icon {
      display: block;
      path {
        fill: #02a388;
      }
    }
  }

  .input {
    display: none;
  }
  
  .text {
    margin-left: .5rem;
    font-size: 0.875rem;
    vertical-align: middle;
    cursor: pointer;
  }
  
  .optionIcon {
    margin-left: auto;
    height: 22px;
    width: 22px;
  }
}

.option:hover .checkboxSelected {
  border: 1px solid #666e6e;

  .icon {
    display: block;
    path {
      fill: #666e6e;
    }
  }
}

.option:hover .checkbox .icon {
  display: block;
  path {
    fill: #666e6e;
  }
}