@import "app/styles/istock/Legacy.module.scss";

.content {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  margin: 0;
  color: $shale-grey;
  font-size: .75rem;
  line-height: normal;
  padding-right: 8px;
}

.descriptionSpacer {
  height: 14px;
  margin: 0;
  padding-top: 6px;
}
