@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.option {
  display: flex;
  align-items: center;
  margin: 8px 0;
  cursor: pointer;

  .radio {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border: 1px solid $is-med-dark-grey;
    border-radius: 100%;
    cursor: pointer;
    flex: 0 0 auto;
    height: 19px;
    width: 19px;
    outline: none;
    display: inline-block;

    &:before {
      content: "";
      display: block;
      height: 11px;
      width: 11px;
      margin: {
        top: 3px;
        left: 3px;
      }
      border-radius: 100%;
    }

    &:checked { 
      border-color: $is-green;
    }

    &:hover {
      border-color: $is-med-dark-grey;
    }

    &:checked:before {
      background-color: $is-green;
    }

    &:not(:checked):hover:before {
      background-color: $is-med-dark-grey;
    }
  }
  
  @mixin baseText {
    margin-left: .5rem;
    font-size: 0.875rem;
    vertical-align: middle;
    cursor: pointer;
  }

  .text {
    @include baseText;
  }

  .textDull {
    @include baseText;
    color: #cccccc;
  }
  
  .icon {
    margin-left: auto;
    height: 25px;
    width: 25px;
  }
}

.option:hover {
  .radio {
    border-color: $is-med-dark-grey;

    &:before {
      background-color: $is-med-dark-grey;
    }
  }
}