@import 'unisporkal-styles/stylesheets/unisporkal/getty/config/_media_breakpoints.scss';

.relatedTerms {
  width: 100%;

  list-style-type: none;
  display: inline-block;

  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;
  margin-bottom: 0px;
}

.relatedTermsItem {
  position: relative;
  display: inline-block;
  height: 40px;

  a {
    height: 100%;
  }

  &:first-child {
    padding-left: 1rem;
  }

  &:last-child {
    margin-right: 0.5rem;
  }

  @media #{$medium-up} {
    &:first-child {
      padding-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
