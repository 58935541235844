@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_functions.scss";

.container {
  display: flex;
  cursor: pointer;

  color: $white;
  background-color: $grey;
  border-bottom: 1px solid $dark-grey;
  padding: 20px 34px;
  top: 0;
  z-index: 4;

  @media #{$istock-large-up} {
    display: none;
  }

  .textHolder {
    display: flex;
    align-items: center;
    margin-left: 0;
    fill: $green;

    &:hover .icon {
      fill: $green;
      transform: scalex(-1);
    }

    .text {
      font-family: iStock Maquette, Helvetica, Arial, sans-serif;
      font-size: rem(14);
      font-weight: 500;
      margin-left: 16px;
      display: flex;
    }
  }

  .iconHolder {
    margin-left: auto;

    .icon {
      fill: $border-dark-grey;
    }
  }
}
