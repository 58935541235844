@import 'app/styles/istock/Legacy.module.scss';
@import 'app/styles/istock/Color.module.scss';
@import 'app/styles/istock/Animation.module.scss';
@import 'app/styles/istock/Zindex.module.scss';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss';

@mixin baseGalleryMosaicAsset {
  margin-right: 0.75em;
  margin-bottom: 0.75em;
  flex-grow: calc(var(--ratio) * 100);
  --ratio: calc(var(--width) / var(--height));
  --row-height: 12em;
  flex-basis: calc(var(--ratio) * var(--row-height));

  @media #{$ie11-media-feature} {
    display: flex;
    flex-basis: auto;
    min-height: auto;
    margin: auto;
    padding: 0.3em;
  }

  @media #{$istock-xlarge-up} {
    --row-height: 16em;
  }

  @media #{$istock-xxlarge-up} {
    --row-height: 18em;
  }

  .container {
    position: relative;
    height: 100%;
    width: 100%;

    .link {
      display: block;
      height: 100%;

      .figure {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $white;

        .backgroundOverlay {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: rgba(black, 0.2);
          opacity: 0;
          transition: opacity 0.3s ease;
          pointer-events: none;
        }

        .thumb {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: opacity $complex-duration ease;
        }

        .figcaption {
          display: none;
        }
      }
    }
  }
}

.clipBadge {
  margin: 10px;
}

.galleryMosaicAsset {
  @include baseGalleryMosaicAsset;
}

.galleryMosaicPanoHoriz {
  @include baseGalleryMosaicAsset;
  @media #{$istock-small-up} {
    --row-height: 12em;
  }
  --row-height: 8em;
}

.galleryMosaicVideo {
  @include baseGalleryMosaicAsset;
  --row-height: 10em;

  @media #{$istock-medium-up} {
    --row-height: 12em;
  }

  @media #{$istock-xxlarge-up} {
    --row-height: 15em;
  }
}

.galleryMosaicAsset:hover .container .figure .backgroundOverlay {
  opacity: 1;
}

.galleryMosaicAsset:hover .actions {
  opacity: 1;
}

.galleryMosaicPanoHoriz:hover .actions {
  opacity: 1;
}

.galleryMosaicPanoHoriz:hover .container .figure .backgroundOverlay {
  opacity: 1;
}

.galleryMosaicVideo:hover .actions {
  opacity: 1;
}

.galleryMosaicVideo:hover .container .figure .backgroundOverlay {
  opacity: 1;
}
