@import "app/styles/istock/Legacy.module.scss";

.container {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: row;
  height: 40px;
  padding: 0;
}
