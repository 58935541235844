@import "app/styles/istock/Legacy.module.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.optionsContainer {
  position: relative;

  display: flex;
  height: calc(100% - 0.75rem);
}

.sections {
  position: relative;
  padding-bottom: 0.75em;
  scrollbar-width: none;
  overflow: auto;
  width: 360px;
  flex-grow: 1;

  &::-webkit-scrollbar {
    display: none;
  }
}

.subheaders {
  padding: 0.75rem 0 0 0.75rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;

  font-size: 0.875rem;
  white-space: nowrap;
  overflow-y: auto;

  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
}

.subheader {
  display: block;
  color: $href_color;
  cursor: pointer;

  &:hover {
    color: $href_hover_color;
  }

  & + & {
    margin-top: 0.25rem;
  }
}

.sectionHeader {
  margin: 0.75em 0;
  padding-bottom: 0.25rem;
  font-size: 0.875rem;
  color: $shale-grey;
  border-bottom: 1px solid $border-grey;
}

.option {
  color: $alpha_gray;
  font-size: .875rem;

  & + & {
    margin-top: 0.5rem;
  }
}

.checkbox {
  margin-right: 0.5rem;
  vertical-align: middle;
  cursor: pointer;

  &:not(:checked):hover {
    background-image: url(~images/checkmark-gray.svg);
    border-color: $alpha_gray;
  }
}

.checkboxLabel {
  vertical-align: middle;
  cursor: pointer;
}

.link {
  &:hover {
    color: $green;
    text-decoration: none;
  }
}

.date {
  color: $shale-grey;
  font-size: .75rem;
  margin-bottom: .25rem;
  margin-top: .75rem;
}
