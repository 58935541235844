@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.content {
  justify-self: center;
  font-size: .75em;
  line-height: 1.25rem;
  padding: 0 0.5rem;
  margin-bottom: 1rem;

  &Top {
    margin-bottom: 0;
    margin-top: 1rem;
  }
}

.contentHidden {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.content a {
  color: $is-green;
}

.content a:hover {
  color: $is-dark-green;
  text-decoration: none;
}

.readMore {
  font-size: .75em;
  line-height: 1.5em;
  font-weight: bold;
  margin-top: -1rem;
  margin-bottom: 1rem;
  cursor: pointer;
  color: $is-green;
  padding: 0 1rem;

  @media #{$medium-up} {
    padding: 0 1.5rem;
  }
}