@import "../GalleryNavigation/GalleryNavigation.module.scss";

.container {
  display: flex;
  font-size: 14px;
  padding: 2rem 1rem 0 1rem;
  flex-direction: column;

  > div:first-child {
    margin-top: auto;
    max-width: 100%;
  }

  @media #{$istock-medium-up} {
    flex-direction: row;
    padding: 2rem 2.5rem 0 2.5rem;
    justify-content: space-between;

    > div:first-child {
      max-width: 33%;
      flex: 0 0 33%;
    }
  }
}
