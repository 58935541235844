@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_functions.scss";

@mixin baseContainer {
  display: flex;
  cursor: pointer;

  color: $white;
  background-color: $grey;
  fill: $is-med-dark-grey;
  border-bottom: 1px solid $dark-grey;

  height: 55px;
  width: 66px;
  min-width: 66px;

  align-items: center;
  justify-content: center;

  @media #{$istock-medium-up} {
    height: 60px;
  }

  @media #{$istock-large-up} {
    width: 216px;
    padding-right: 40px;
    padding-left: 40px;
    align-items: unset;
    justify-content: unset;
  }

  &:hover .icon {
    fill: $green;
    transform: scalex(-1);
  }

  .holder {
    display: flex;
    align-items: center;
    margin-left: 0;

    .text {
      font-family: iStock Maquette, Helvetica, Arial, sans-serif;
      font-size: rem(16);
      font-weight: 500;
      margin-left: 16px;
      display: none;

      @media #{$istock-large-up} {
        display: flex;
      }
    }
  }
}

.containerShort {
  @include baseContainer;
  transition: all $complex-duration ease-in-out;
}

.containerShortDelay {
  @include baseContainer;
  transition: all $complex-duration ease-in-out;
  transition-delay: 0.5s;
}

.containerWide {
  @include baseContainer;
  @media #{$istock-large-up} {
    min-width: 265px;
    width: 265px;
  }
  fill: $green;
}

.containerHidden {
  @include baseContainer;
  @media #{$istock-large-up} {
    display: none;
    min-width: 0px;
    width: 0px;
    padding-right: 0;
    padding-left: 0;
  }
}
