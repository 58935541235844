@import "app/styles/istock/Legacy.module";
@import "app/styles/istock/Color.module";

.headline {
  font-size: .875rem;
  font-weight: 500;
  line-height: 1em;
  margin-right: 16px;

  &:first-child {
    font-size: 16px;
    font-weight: normal;
    line-height: 2;
  }

  &:last-child {
    margin-top: 10px;
    align-self: center;
  }

  > a {
    font-weight: 500;
  }

  @media #{$istock-medium-up} {
    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-top: 0;
      align-self: unset;
    }
  }
}

.icon {
  margin-right: 8px;
  fill: $href_color;
}

.link {
  &:first-child {
    margin-bottom: 10px;
  }

  @media #{$istock-medium-up} {
    line-height: 1em;
    margin-right: 12px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  line-height: 1em;
  color: $href-color;

  a {
    display: flex;
    align-items: center;
  }

  &:hover, &:focus {
    color: $href_hover_color;

    .icon {
      fill: $href_hover_color;
    }
  }
}

.compact {
  &:first-child {
    font-size: 14px;
  }

  > a {
    font-size: 14px;
    font-weight: 400;
  }
}
