@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

%coextended-absolute {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

%background {
  @extend %coextended-absolute;
  background-position: center center;
  background-size: cover;
  display: block;
  overflow: hidden;
}

.banner {
  background-color: $light-grey;
  display: block;
  height: 240px;
  position: relative;
  width: 100%;

  @media #{$small-only} {
    height: auto;
    padding: 24px 0;
  }
}

.gradient {
  @extend %background;
  background-image: (linear-gradient(to bottom, rgba(12,13,13,0), rgba(12,13,13,0.3)));

  img {
    width: 136%; 
    height: 136%;
  }
}

.avatar {
  @extend %coextended-absolute;
  height: 134px;
  margin: auto;
  text-align: center;
  transition: all .2s ease-in-out;
  width: 134px;

  @media #{$small-only} {
    position: relative;
  }

  svg {
    fill: $shale-grey;
  }
}

.avatarImage {
  background-size: cover;
  border-radius: 66px;
  height: 134px;
  margin: auto;
  position: absolute;
  top: 0;
  width: 134px;
}

.featuredIcon {
  transform: (translateY(1px));
  margin-right: 7px;
  width: 23px;
  height: 23px;
}

.profile {
  @extend %coextended-absolute;
  height: 240px;
  line-height: 240px;
  transition: all .2s ease-in-out;

  @media #{$small-only} {
    display: block;
    height: auto;
    line-height: 1;
    position: relative;
    width: auto;
  }

  > div {
    color: white;
    display: inline-block;
    font-size: rem-calc(16);
    line-height: 1.4;
    vertical-align: middle;
    width: 50%;

    @media #{$small-only} {
      display: block;
      height: auto;
      line-height: 1;
      position: relative;
      width: auto;
    }
  }
}

.profilePrimary {
  height: 62px;

  @media #{$small-only} {
    margin: rem-calc(8);
    height: auto;
  }

  > * {
    margin-right: 100px;
    text-align: right;

    @media #{$small-only} {
      margin-left: 0;
      margin-right: 0;
      padding: 4px 0;
      text-align: center;
    }
  }
}

.profileSecondary {
  height: 55px;

  @media #{$small-only} {
    margin: rem-calc(8);
    height: auto;
  }

  > * {
    margin-left: 106px;
    margin-top: 6px;

    @media #{$small-only} {
      margin-left: 0;
      margin-right: 0;
      padding: 4px 0;
      text-align: center;
    }
  }
}

.name {
  font-size: rem-calc(24);
  font-weight: normal;

  svg {
    fill: $white;
    white-space: pre-wrap;
    cursor: pointer;
  }
}

.website {
  a {
    color: rgba(255, 255, 255, 1.0);
  }

  a:hover {
    color: rgba(255, 255, 255, .75);
  }
}