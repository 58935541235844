@import "app/styles/istock/Legacy.module.scss";

.container {
  padding: 20px 20px 210px 24px;
  border-top: 1px solid #4d5454;
}

.clearButton {
  @extend .hollow-cta;
  @extend .hollow-cta, .medium;
  @extend .hollow-cta, .dark-bg;
  display: inherit;
  width: 185px;
  margin-bottom: 0.5rem;
  margin: auto;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
}
