@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 0 1rem;

  @media #{$istock-medium-up} {
    padding: 0 2.5rem;
  }
}

.link {
  all: unset;

  cursor: pointer;
  line-height: 1;
  display: flex;
  align-items: baseline;
  color: $href-color;
  font-size: 0.875rem;
  &:hover {
    color: $href-hover-color;
  }

  .icon {
    margin-right: 8px;
    fill: $href-color;
  }

  a {
    all: unset;
    &:hover {
      text-decoration: none;
    }
  }
}

.breadcrumbs {
  margin-top: 2rem;
  margin-bottom: -1rem;
}

.noticeContent {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  min-width: 0;
  margin: 10px 0;
}
