@use './ModificationToolsMenu.shared.module.scss';
@use '@unisporkal/alliance-ui-theme/dist/styles/getty' as gettyTheme;

.rootMosaic {
  background-color: gettyTheme.$true-white;
  border-color: gettyTheme.$gi-black;
}

.dropdown {
  .button {
    &:hover,
    &:focus {
      background-color: gettyTheme.$extra-light-grey !important;
    }

    &:disabled {
      color: gettyTheme.$grey;
    }
  }
}

