@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

@import './RelatedTerms.module.scss';

.relatedTerms {
  @media #{$istock-medium-up} {
    overflow-x: hidden;
  }
}

.relatedTermsItem {
  &:first-child {
    padding-left: 0;
  }
}