.container {
  width: 280px;
  min-height: 244px;
  padding: 8px 0;
  gap: 4px;
}

.headerRowContainer {
  gap: 5px;
}

.video {
  width: 100%;
  height: 100%;
  margin-bottom: 12px;
}

.newBadge {
  padding: 2px 4px;
  border-radius: 5px;
}

.header {
  font-weight: 700;
}
