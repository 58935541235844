@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.range {
  position: relative;
  width: 100%;
  margin-top: 16px;

  .track {
    position: absolute;
    width: 100%;
    height: .25rem;
    background: $grey-70;
    border: none;
    border-radius: 5px;
  }

  .fill {
    position: absolute;
    width: 100%;
    height: .25rem;
    background: $green;
    border: none;
    border-radius: 5px;
  }

  .textHolder {
    display: flex;
    width: 100%;
    padding-top: 1rem;
    justify-content: space-between;

    .text {
      font-size: 0.875rem;
    }
  }

  .slider {
    position: absolute;
    padding: 0;
    margin: 0;
    outline: none;
    pointer-events: none;
    -webkit-appearance: none;
    width: 100%;
    height: .25rem;
    background: transparent;
    border: none;
    border-radius: 5px;

    &::-webkit-slider-runnable-track {
      width: 100%;
      height: .25rem;
      background: transparent;
      border: none;
      border-radius: 5px;
    }

    &::-moz-range-track {
      width: 100%;
      height: .25rem;
      background: transparent;
      border: none;
      border-radius: 5px;
    }

    &::-ms-track {
      width: 100%;
      height: .25rem;
      background: transparent;
      border: none;
      border-radius: 5px;
    }

    &::-webkit-slider-thumb {
      margin-top: -0.4rem;
      -webkit-appearance: none;
      width: 1rem;
      height: 1rem;
      background: $white;
      border: 1px solid black;
      border-radius: 50%;
      pointer-events: auto;

      &:hover {
        cursor: pointer;
        background-color: $shale-grey;
      }

      &:active {
        background: $green
      }
    }

    &::-moz-range-thumb {
      width: 1rem;
      height: 1rem;
      background: $white;
      border: 1px solid black;
      border-radius: 50%;
      pointer-events: auto;

      &:hover {
        cursor: pointer;
      }

      &:active {
        background: $green;
      }
    }

    &::-ms-thumb {
      width: 1rem;
      height: 1rem;
      background: $white;
      border: 1px solid black;
      border-radius: 50%;
      pointer-events: auto;

      &:hover {
        cursor: pointer;
      }

      &:active {
        background: $green;
      }
    }
  }
}
