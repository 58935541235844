@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss";

.content {
  position: relative;
  padding: 10px 18px;
  white-space: nowrap;

  background: #e8eded;
  border-top: 1px solid $white;

  .title {
    font-size: 0.75rem;
    color: $dark-grey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .list {
    font-size: 0.75em;
    color: $grey-70;
    line-height: 1.4;
    margin-bottom: 0px;
  
    .item {
      color: black;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    
      .link {
        display: block;
        margin-bottom: 4px;
        cursor: pointer;
      }
    }
  }
}