@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: $shale-grey;
  padding: 2rem 1rem 2rem 1rem;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  
  height: 60px;
  width: 155px;
  font-size: .875rem;
  
  color: $shale-grey;
  background-color: $white;
  border: 1px solid $some-grey-border;
  border-radius: 4px;
  
  &:hover {
    background: white;
    box-shadow: inset 0 0 0 3px $grid-grey;
    border-color: $some-grey-border;
    color: black;

    .icon {
      fill: $dark-grey;
    }
  }

  @media #{$istock-small-up} {
    font-size: 1rem;
  }
}

.nextButton {
  margin-left: 1rem;
  @media #{$istock-medium-up} {
    margin-left: 50px;
  }
}

.previousButton {
  margin-right: 1rem;
  @media #{$istock-medium-up} {
    margin-right: 50px;
  }
}

.buttonText {
  margin: 0 6px;
}

.icon {
  fill: $shale-grey;
}

.previousIcon {
  transform: rotateY(180deg);
}

.lastPage {
  padding-left: 5px;
}

.input {
  width: 40px;
  height: 33px;
  font-size: 16px;
  text-align: center;
  margin: 8px;
  border: 1px solid $some-grey-border;
  background-color: $white;

  &:focus {
    outline: none;
  }
}

#paginationInput {
  width: 60px;
  height: 33px;
  border: 1px solid $some-grey-border;
  color: $shale-grey;

  &:focus, &:hover {
    box-shadow: inset 0 0 6px rgba(black, 0.1);
    border-color: $light-grey;
    color: $dark-grey;
    border: 2px solid $shale-grey;
  }
  &:focus {
    border-color: $dark-grey;
    outline: none;
  }
}