@import "./GalleryTabBar.module.scss";

.tabs {
  display: flex;
  margin: auto;
  width: 64%;
  max-width: 600px;
  min-width: 475px;

  > .tab {
    font-size: 1rem;
    height: auto;
    width: 33%;
    padding: 10px 30px;

    > div {
      font-size: 12px;
    }
  }

  @media #{$istock-medium-up} {
    flex: 1 1 33%;
    margin-top: auto;
    margin-bottom: auto;
    justify-content: center;
    min-width: 362px;
  }
}
