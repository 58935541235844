@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: 1;
}

.moodsContainer {
  margin-top: 12px;
  margin-bottom: 12px;

  padding: {
    left: 1rem;
    right: 1rem;
  }

  @media #{$istock-medium-up} {
    padding: {
      right: 1.5rem;
      left: 1.5rem;
    }
  }
}

.moodsContent {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  flex-direction: column-reverse;
  justify-content: space-between;

  @media #{$istock-medium-up} {
    margin-top: 1rem;
    align-items: center;
    flex-direction: row;
  }
}

.icon {
  margin-right: 8px;
  fill: $href_color;
}

.link {
  &:first-child {
    margin-bottom: 10px;
  }

  @media #{$istock-medium-up} {
    line-height: 1em;
    margin-right: 12px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  line-height: 1em;
  color: $href-color;

  a {
    display: flex;
    align-items: center;
  }

  &:hover, &:focus {
    color: $href_hover_color;

    .icon {
      fill: $href_hover_color;
    }
  }
}



.icon {
  margin-right: 8px;
  fill: $href_color;
}

.link {
  &:first-child {
    margin-bottom: 10px;
  }

  @media #{$istock-medium-up} {
    line-height: 1em;
    margin-right: 12px;

    &:first-child {
      margin-bottom: 0;
    }
  }

  line-height: 1em;
  color: $href-color;

  a {
    display: flex;
    align-items: center;
  }

  &:hover, &:focus {
    color: $href_hover_color;

    .icon {
      fill: $href_hover_color;
    }
  }
}

.pivotLinks {
  width: auto;
  margin-top: 13px;

  @media #{$istock-medium-up} {
    display: flex;
    margin-top: 0;
  }
}

a {
  cursor: pointer;
}
