@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.tileContainer {
  background-image: url("./tile_background.png");
  background-size: cover;
  background-repeat: no-repeat;
  color: rgba($is-black, 0.75);
  padding: 24px;
  margin-right: 12px;
  margin-bottom: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  min-height: 310px;
  width: 472px;
  font-size: 1.1rem;
  line-height: normal;

  // Mobile
  @media #{'screen and (max-width: 540px)'} {
    display: none;
  }

  // Tablet
  @media #{'screen and (max-width: 800px)'} {
    flex-direction: column;
    padding: 32px;
    width: 100%;
  }

  &:hover {
    color: $is-black;

    .tileButton {
      background-color: $white;
      color: $gi-black;

      .tileIcon {
        path {
          fill: $gi-black;
        }
      }
    }
  }
}

.subHeaderText {
  padding-top: 5px;
  font-size: 0.85rem;
  font-weight: normal;
}

.tileContainer .tileButton {
  background-color: rgba($gi-black, 0.5);

  &:hover {
    background-color: $white;

    .tileIcon {
      path {
        fill: $gi-black;
      }
    }
  }
}

.tileButton {
  color: $white;
  display: flex;
  text-decoration: none;
  border-radius: 7px;
  padding: 10px 30px;
  cursor: pointer;
  max-width: 100%;
}

.tileIcon {
  height: 20px;
  padding-right: 8px;
  path {
    fill: $white;
  }
}
