@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  display: block; 
  padding: {
    left:  1rem;
    right: 1rem;
    top: 1rem;
  }

  @media #{$istock-medium-up} {
    display: flex;
    padding: {
      right: 2rem;
      left: 2rem;
    }
  }
}

.gridToggle {
  display: none;
  @media #{$istock-medium-up} {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end; 
  }

  .label {
    display: flex;
    margin-top: 9px;
    margin-left: 12px;
    cursor: pointer;

    .icon {
      fill: $is-med-light-grey;
    }
  }

  .labelSelected {
    display: flex;
    margin-top: 9px;
    margin-left: 12px;
    cursor: pointer;

    .icon {
      fill: black;
    }
  }
}

.relatedTermsWrapper {
  padding: 1rem 1rem 0 1rem;
}