// iStock specific colors
$is-green         : $green;
$is-dark-green    : $href_hover_color;
$is-grey          : $some-grey-border;
$is-light-grey    : $some-grey;
$is-med-light-grey: #b3b3b3;
$is-med-grey      : $shale-grey;
$is-med-dark-grey : #8d8e8e;
$is-dark-grey     : $grey;
$is-black         : $dark-grey;
$is-light-teal    : #e8f4f2;
$is-red           : $red;
$is-white         : $white;

$is-med-grey-10: darken($is-med-grey, 10%);
$grey-70       : #666666;
$grey-40       : #CCCCCC;
$is-background-grey: #0c0d0d66;
