@use '@unisporkal/alliance-ui-theme/dist/styles/common' as commonTheme;

.wrapper {
  z-index: 2;
  position: relative;
}

.dropdown {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-width: 1px;
  border-style: solid;
  padding: 8px 0;
  width: max-content;
  cursor: auto;

  .button {
    width: 100%;
    padding: 6px 16px;
    justify-content: flex-start;

    &:hover,
    &:focus {
      text-decoration: none !important;
    }

    &:disabled {
      background-color: commonTheme.$true-white;
    }
  }
}

.tooltipContent {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.generateButton {
  width: 38px;
  height: 38px;
  border-radius: 5px;
  padding-top: 4px;
}

.generateButtonMosaic {
  margin-bottom: 20px;
}

.generateButtonAsset {
  color: #666e6e;
}

.icon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}
