@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss";


.actions {
  height: auto;
  background-color: white;
  border: none;

  position: absolute;
  left: 0;
  width: 100%;

  opacity: 1;
  bottom: 80px;

  .actionsContainer {
    display: flex;
    align-items: center;
    margin: 0 13px;
    z-index: 1;

    justify-content: space-between;

    .signature {
      justify-content: flex-start;

      background: $white;
      color: $medium-grey;
      font-size: 0.875rem;
      line-height: 19px;
      padding: 4px 0;
    }

    .freeTrialCta {
      justify-content: flex-start;
      font-size: 0.875rem;
      line-height: 19px;
      padding: 4px 0;
      color: $green;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .containerRight {
      display: flex;
      fill: #666e6e;
      margin-left: auto;

      .similarImages {
        position: relative;
        display: flex;
        margin: 10px;
        cursor: pointer;

        color: lightgrey;
        font-size: 0.75rem;
        line-height: 24px;
        -webkit-tap-highlight-color: transparent;
      }

      .addToBoards {
        justify-content: flex-start;
        align-items: center;

        display: flex;
        cursor: pointer;
        background: none;
        path {
          fill: #666e6e;
        }

        &:hover {
          path {
            fill: black;
          }
        }
      }
    }
  }
}

.actionToolTip {
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: -0.75rem;
    left: 50%;

    height: 0;
    width: 0;

    visibility: hidden;
    opacity: 0;
    transform: translate3d(-50%, 4px, 0);
    transition: all $complex-duration ease;
  }

  &:before {
    border: {
      top: 8px solid rgba($dark-grey, 0.4);
      right: 6px solid transparent;
      left: 6px solid transparent;
    }
  }

  &:after {
    border: {
      top: 7px solid $white;
      right: 5px solid transparent;
      left: 5px solid transparent;
    }
  }

  &:hover {
    &:before,
    &:after {
      visibility: visible;
      opacity: 1;
      transform: translate3d(-50%, 0, 0);
    }
  }
}

.actionToolTipRight {
  &:before {
    left: initial;
    right: 0px;
  }
  &:after {
    left: initial;
    right: 2px;
  }
}

.actionToolTipContainer {
  position: absolute;
  bottom: 105%;

  margin: 0.625rem;

  font-size: 12px;
  font-weight: normal;
  color: black;
  background-color: $white;
  border: 1px solid rgba($dark-grey, 0.4);
  border-radius: 2px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transform: translate3d(-50%, 0, 0);
  transition: all $complex-duration ease;
}

.actionToolTip:hover .actionToolTipContainer {
  visibility: visible;
  opacity: 1;
  transform: translate3d(-50%, 0, 0);
}

.similar {
  bottom: 24px;
  width: 155px;
  margin: 4px;
  text-align: center;
}
