@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";

.keywordContainer {
  width: 100%;
  margin: 0;
  list-style-type: none;
  display: inline-block;

  white-space: nowrap;
  overflow-x: scroll;
  overflow-y: hidden;

  @media #{$istock-medium-up} {
    overflow-x: hidden;
  } 
}

.keywordListItem {
  position: relative;
  display: inline-block;
  
  &:last-child {
    margin-right: 0.5rem;
  }

  @media #{$istock-medium-up} {
    &:last-child {
      margin-right: 0;
    }
  }
}
