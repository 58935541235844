@import "unisporkal-styles/stylesheets/unisporkal/getty/config/_colors";
@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  margin-right: 8px;
  z-index: 1;
}

.menu {
  padding-top: 41px;
  padding-bottom: 11px;
  padding: 0;
  border-radius: 10px;
  z-index: 10;
  border: 1px solid $is-grey;
  margin-right: 20px;
  > div {
    max-height: 400px;
  }
}

.control {
  padding-left: 5px; 
  border-radius: 20px;
  min-height: 40px;
  box-shadow: none;
  cursor: pointer;
  font-size: 14px;

  &:hover {
    border-color: $gi-black;
  }
}

.controlActive {
  border-color: $gi-black;
}

.option {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: inherit;
  font-size: 14px;
  

  &:first-of-type {
    margin-top: 11px;
  }

  &:last-of-type {
    margin-bottom: 11px;
  }

  &:hover {
    background-color: $med-light-grey;
  }

  label {
    cursor: pointer;
    color: $gi-black;
  }
}

.optionActive {
  opacity: .3;
  pointer-events: none;

  label {
    color: $gi-black;
  }
}

.img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.label {
  margin-left: 10px;
  margin-right: 10px;
  color: $gi-black;
  margin-bottom: 0;
}

.subLabel {
  color: $shale-grey;
  font-size: 0.75rem;
  margin-left: 10px;
  padding: 0;
}

.flexCol {
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
