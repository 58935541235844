@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";
@import "app/styles/istock/Animation.module.scss";
@import "unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support";


$onoffswitch_size: 19px;
$onoffswitch_border: 1px;

.sliderWrapper {
  padding: {
    top: 5px;
    bottom: 5px;
  }

  display: flex;
  align-items: center;
}

.slider {
  width: $onoffswitch_size * 2 - $onoffswitch_border;
  min-width: $onoffswitch_size * 2 - $onoffswitch_border;

  position: relative;
  display: inline-block;
  
  user-select: none;
}

.sliderText {
  margin-left: 20px;
  cursor: pointer;
  vertical-align: middle;
  font-size: 0.875rem;
}

.sliderLabel {
  padding: 0px;

  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px;
  height: $onoffswitch_size;

  .selected & {
    border-color: $is-dark-grey;
  }
}

.sliderInner {
  width: 200%;
  margin-left: -100%;
  
  transition: margin $complex-duration ease;

  .selected & {
    margin-left: 0;
  }

  &:before, &:after {
    content: "";

    width: 50%;
    height: $onoffswitch_size + $onoffswitch_border;

    float: left;
    background-color: $is-black;

    .selected & {
      background-color: $is-green;
    }
  }
  
  &:before {
    background-image: url(~images/checkmark.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}

.sliderSwitch {
  position: absolute;
  right: $onoffswitch_size - ($onoffswitch_border);
  bottom: 0;
  width: 17px;
  height: 17px;
  margin: $onoffswitch_border;

  display: block;
  background: $white;
  border-radius: 2px;
  transition: all $complex-duration ease;

  .selected & {
    right: 0px;
    border-color: $is-green;
  }
}