
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_config";
@import "unisporkal-styles/stylesheets/unisporkal_engine/istock/_media_breakpoints.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  display: none;

  @media #{$istock-medium-up} {
    display: flex;
    position: relative;
  }
}

.itemContainer {
  display: flex;
  overflow: hidden;

  &::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    height: 0;
    background: transparent;
  }
}

.navigationColor {
  background-color: $white !important;

  &:hover {
    background-color: $border-grey !important;
  }
}
