@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.tabs {
  display: flex;
  margin: auto;
  width: auto;
  max-width: unset;
  min-width: unset;
  justify-content: flex-start;

  > .tab {
    line-height: 1.25;
    color: $is-black;
    display: inline-flex;
    flex: 0 1 auto;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    width: auto;
    border: 1px solid $is-grey;
    border-right: none;
    overflow: hidden;
    transition: box-shadow .25s ease-in-out;
    background: white;
    cursor: pointer;
    font-size: 1rem;
    font-weight: 500;
    padding: 7px 20px;
    min-width: 130px;

    &:hover {
      color: black;
    }

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    &:last-of-type {
      border-right: 1px solid $is-grey;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &___active {
      background: $is-grey;
    }

    &:not(.tab___active):hover {
      box-shadow: inset 0 0 0 3px #e8eded;
    }

    div {
      color: $shale-grey;
      font-size: 0.875rem;
    }
  }
}