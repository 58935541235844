@import "app/styles/istock/Legacy.module.scss";

.container {
  display: flex;
  flex-wrap: wrap;

  padding: 0 0.25rem 0 1rem;

  @media #{$istock-medium-up} {
    padding: {
      right: 0.75rem;
      left: 1.5rem;
    }
  }
}

.container::after {
  --width: 2;
  --height: 1;
  --ratio: calc(var(--width) / var(--height));
  --row-height: 12rem;
  flex-basis: calc(var(--ratio) * var(--row-height));
  content: '';
  flex-grow: 1000000;
}