@import "app/styles/istock/Legacy.module.scss";

.container {
  padding: 20px 20px 20px 40px;
  border-top: 1px solid #4d5454;
  border-bottom: 1px solid #0c0d0d;
  color: white;

  @media #{$istock-large-up} {
    width: 265px;
    padding-right: 20px;
  }

  @mixin baseHeader {
    font-size: 1rem;
    .icon {
      fill: #adb9ba;
    }
  }

  @mixin baseAccordionHeader {
    @include baseHeader;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer
  }

  .header {
    @include baseHeader;
    .icon {
      display: none;
    }
  }

  .headerClosed {
    @include baseAccordionHeader;
  }

  .headerOpen {
    @include baseAccordionHeader;
    margin-bottom: 1rem;
    .icon {
      transform: scaleY(-1);
      fill: white;
    }
  }
}

.modalLinks {
  margin-bottom: 24px;
}

.modalLinkButton {
  display: block;
  cursor: pointer;
  font-size: .875rem;
  color: $href_color;
  background: transparent;
  margin-bottom: 0.75rem;

  &:hover, &:active {
    color: $href_hover_color;
    text-decoration: none;
  }
}
