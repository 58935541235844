@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.container {
  display: flex;
  align-items: flex-end;
  position: relative;
  font-size: 14px;
  top: 5px;
  margin-left: 20px;

  .label {
    color: $grey-70;
    white-space: nowrap;
  }
}

.alignRight {
  align-self: end;
}
