$base-z-index: 0;

$search-bar-form-z-index: $base-z-index + 1;
$relaxed-queries-z-index: $base-z-index + 1;
$search-overlay-z-index: $search-bar-form-z-index + 1;
$filter-dropdown-z-index: $search-overlay-z-index;
$filter-panel-z-index: $search-overlay-z-index + 1;
$sticky-filter-dropdown-z-index: $filter-panel-z-index + 1;
$media-type-z-index: $filter-panel-z-index + 1;
$gallery-preview-z-index: $media-type-z-index + 1;
$action-item-z-index: $gallery-preview-z-index + 1;
$gallery-overlay-z-index: $action-item-z-index + 1;
