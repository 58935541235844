@import "app/styles/istock/Legacy.module.scss";
@import "app/styles/istock/Color.module.scss";

.linksContainer {
  display: block;
  width: 100%;

  &>div:nth-child(n) {
    margin-top: 12px;
  }

  @media #{$istock-medium-up} {
    display: flex;
    flex-direction: column;
  }
}

.appliedFiltersContainer {
  margin: 0;
}

.appliedFilterPill {
  margin-bottom: 0;
}

.appliedFilterClearAll {
  color: $is-green;
  margin-bottom: 0;
}
