@import 'app/styles/istock/Legacy.module.scss';
@import 'app/styles/istock/Color.module.scss';
@import 'app/styles/istock/Animation.module.scss';
@import 'unisporkal-styles/stylesheets/unisporkal/shared/config/_ie_support.scss';

@mixin baseGalleryGatewayAsset {
  margin-right: 0.75em;
  margin-bottom: 0.5em;
  z-index: 1;
  height: calc(100% - 52px);

  &.withDescription {
    height: calc(100% - 64px);
  }

  &.withKeywords {
    height: calc(100% - 106px);
  }

  .container {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    width: 100%;

    .link {
      display: block;
      height: 100%;
      width: 100%;

      .figure {
        position: relative;
        display: block;
        width: 100%;
        height: 100%;
        background-color: $white;

        .backgroundOverlay {
          position: absolute;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background-color: rgba(black, 0.2);
          opacity: 0;
          transition: opacity 0.3s ease;
          pointer-events: none;
        }

        .thumb {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: opacity $complex-duration ease;
        }

        .figcaption {
          display: none;
        }
      }
    }
  }

  .filmDefinition {
    position: absolute;
    top: 16px;
    left: 16px;
    padding: 0px 2px;
    background-color: rgba($dark-grey, 0.4);
    outline: 1px solid rgba($white, 0.16);
    z-index: 50;
    height: 20px;
    width: 28px;
  }
}

.clipBadge {
  margin: 10px;
}

.galleryGatewayAsset {
  @include baseGalleryGatewayAsset;
}

.galleryGatewayPanoHoriz {
  @include baseGalleryGatewayAsset;

  @media #{$istock-small-up} {
    --row-height: 12em;
  }
  --row-height: 8em;
}

.galleryGatewayVideo {
  @include baseGalleryGatewayAsset;

  --row-height: 10em;

  @media #{$istock-medium-up} {
    --row-height: 12em;
  }

  @media #{$istock-xxlarge-up} {
    --row-height: 15em;
  }
}

.galleryGatewayAsset:hover .actions {
  opacity: 1;
}

.galleryGatewayAsset:hover .container .figure .backgroundOverlay {
  opacity: 1;
}

.galleryGatewayPanoHoriz:hover .actions {
  opacity: 1;
}

.galleryGatewayPanoHoriz:hover .container .figure .backgroundOverlay {
  opacity: 1;
}

.galleryGatewayVideo:hover .actions {
  opacity: 1;
}

.galleryGatewayVideo:hover .container .figure .backgroundOverlay {
  opacity: 1;
}

.title {
  font-size: 0.875rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 8px;

  a {
    color: $grey;
    cursor: pointer;
    font-weight: normal;
  }
}

@mixin parentContainer {
  object-fit: cover;
  --new-height: calc(var(--height) + 65);
  --ratio: calc(var(--width) / var(--new-height));
  --row-height: 14em;
  flex-grow: calc(var(--ratio) * 100);
  flex-basis: calc(var(--ratio) * var(--row-height));

  @media #{$ie11-media-feature} {
    display: flex;
    flex-basis: auto;
    min-height: auto;
    margin: auto;
    padding: 0.3em;
  }

  @media #{$istock-medium-up} {
    margin-bottom: 0;
  }

  @media #{$istock-xlarge-up} {
    --row-height: 18em;
  }

  @media #{istock-$xxlarge-up} {
    --row-height: 20em;
  }
}

.filmContainer {
  @include parentContainer;
  margin-bottom: 1em;
  width: 33%;
  &.vertical {
    width: 17%;
  }
}

.imageContainer {
  @include parentContainer;
  width: 15%;

  @media #{$istock-medium-up} {
    --row-height: 18em;
  }
}

.keywordCarousel {
  height: 33px;
  margin-top: 10px;
}

.keywordsSpacer {
  height: 33px;
  margin-top: 10px;
}

.assetDetails {
  padding: 0 10px;
  margin-top: 10px;
}

.contentSpacer {
  height: 20px;
  margin: 0;
  padding-top: 4px;
}
